/*
 * @Description: apis
 * @Author: yaoxiaoxiao
 * @RearEnd: 郑晓
 * @Date: 2021-03-31 17:19:36
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-06 14:21:06
 */

import service from '@/utils/request'

// 主列表
const LIST_PATH = '/admin/systemParam/getPageOfSystemParam'
// 编辑
const EDIT_PATH = '/admin/systemParam/editSystemParam'
// 详情
const DETAIL_PATH = '/admin/systemParam/getSystemParamDetail'

/**
* @description: 列表分页查询
* @param {any} data
* @return {object}
*/
export const getListAPI = (data: any): object => {
  return service.request({
    url: LIST_PATH,
    method: 'post',
    data
  })
}

/**
  * @description: 修改|编辑
  * @param {any} data
  * @return {object}
  */
export const editAPI = (data: any): object => {
  return service.request({
    url: EDIT_PATH,
    method: 'post',
    data
  })
}

/**
  * @description: 获取详情
  * @param {any} data
  * @return {object}
  */
export const detailAPI = (data: any): object => {
  return service.request({
    url: DETAIL_PATH,
    method: 'post',
    data
  })
}
