import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "main-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_search_list = _resolveComponent("c-search-list")
  const _component_el_button = _resolveComponent("el-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_Dialog = _resolveComponent("Dialog")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_c_search_list, {
        inputList: _ctx.inputList,
        onOnSearch: _ctx.handleSearch,
        onOnReset: _ctx.handleReset
      }, null, 8, ["inputList", "onOnSearch", "onOnReset"]), [
        [_directive_hasAuth, 'parameter-query']
      ]),
      _createVNode(_component_c_table, {
        dataSource: _ctx.dataSource,
        onPageCurrentChange: _ctx.pageCurrentChange,
        onPageSizeChange: _ctx.pageSizeChange
      }, {
        action: _withCtx((data) => [
          _withDirectives(_createVNode(_component_el_button, {
            type: "text",
            size: "small",
            onClick: ($event: any) => (_ctx.handleToggle('isDialog', data.row))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.btnName('parameter-edit') || '修改'), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]), [
            [_directive_hasAuth, 'parameter-edit']
          ])
        ]),
        _: 1
      }, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"])
    ]),
    (_ctx.isDialog)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          visible: _ctx.isDialog,
          row: _ctx.currentRow,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleToggle('isDialog'))),
          "onUpdate:list": _ctx.getList
        }, null, 8, ["visible", "row", "onUpdate:list"]))
      : _createCommentVNode("", true)
  ], 64))
}