
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { btnName } from '@/utils/index'

import { defineComponent, reactive, toRefs } from 'vue'
import { inputList, getTableConfig } from './config'
import Dialog from './components/dialog.vue'
import { toResult } from '@/utils/toResult'
// import { getListAPI } from './mock'
import { getListAPI } from './api'
import { usePagination } from '@/hooks/usePagination'
export default defineComponent({
  name: 'maintenance-parameter',
  components: { CSearchList, CTable, Dialog },
  setup() {
    // 组件全局响应状态
    const state = reactive({
      inputList: inputList(),
      dataSource: getTableConfig(),
      isDialog: false,
      currentRow: null,
      searchParams: null
    })

    /**
     * @description: 异步获取列表数据
     * @param {*}
     * @return {void}
     */
    const getList = async (params: object): Promise<void> => {
      state.dataSource.loading = true
      const p = {
        currPage: state.dataSource.pagination.currentPage,
        pageSize: state.dataSource.pagination.pageSize,
        ...params
      }
      const [err, data] = await toResult(getListAPI(p))
      state.dataSource.loading = false
      if (err) return
      console.log(data)
      state.dataSource.data = data.list || []
      state.dataSource.pagination.total = data.total || 0
    }

    /**
     * @description: 执行搜索
     * @param {object} params
     * @return {void}
     */
    const handleSearch = (params: object | null): void => {
      state.dataSource.pagination.currentPage = 1
      params && (state.searchParams = { ...params })
      getList(state.searchParams)
    }

    /**
     * @description: 执行重置
     * @param {object} params
     * @return {void}
     */
    const handleReset = (params: object): void => {
      state.searchParams = params
    }

    /**
     * @description: 切换显示组件
     * @param {*} name
     * @param {*} row
     * @return {void}
     */
    const handleToggle = (name: string, row: object | null): void => {
      state[name] = !state[name]
      row && (state.currentRow = row)
    }

    // setup 时执行一次
    handleSearch(null)

    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      state.dataSource.pagination,
      () => {
        getList(state.searchParams)
      }
    )

    return {
      ...toRefs(state),
      btnName,
      handleSearch,
      handleReset,
      pageCurrentChange,
      pageSizeChange,
      handleToggle,
      getList
    }
  }
})
